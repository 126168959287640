.toast-container {
  display: flex !important;
  align-items: center;
  background: black !important;
  color: white !important;
  opacity: 0.85;
  padding-left: 15px !important;
  border-radius: 5px !important;
  button {
    color: white !important;
    opacity: 1;
  }
  .message {
    margin-left: 10px;
  }
}
