.textComponent {
	display: flex;
	align-items: center;
	text-transform: none;
	padding-top: 1px;
	padding-bottom: 5px;
	padding-left: 10px;
	i {
		width: 20px;
	}
	.vty {
		flex: 1;
	}
	.whitec {
		color: #fff;
		font-weight: 600;
		font-size: 15px;
	}
	.cinec {
		font-size: 13px;
	}

	.email {
		text-decoration: underline;
	}
}
