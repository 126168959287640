html {
  box-sizing: border-box;
  height: 100%;
}
:root {
  --primary: #83b151;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  height: 100%;
  width: 100%;
  color: #40485a;
  font-weight: 400;
  font-size: 14px;
  font-family: Roboto !important;
  background: #efefef;
}
body.sidebar-open {
  overflow: hidden;
  position: fixed;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
h1 {
  font-size: 2.4rem;
}
h2 {
  font-size: 2.2rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.8rem;
}
h5 {
  font-size: 1.6rem;
}
h6 {
  font-size: 1.4rem;
}
a {
  color: #40485a;
  cursor: pointer;
}
a:hover {
  color: var(--primary);
  text-decoration: none;
}
button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.iss {
  color: #d3d5d9;
  font-size: 12px;
  margin-right: 3px;
}
.positive {
  color: #f9c95c;
}

.hide {
  display: none !important;
}
@media (min-width: 1025px) {
  .hidedesk {
    display: none !important;
  }
}
@media (max-width: 1024px) {
  .hidemob {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hidemob {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .hidemob {
    display: none !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
